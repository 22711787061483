import { getSubscriptionJS } from './getSubscriptionJS'
import * as Sentry from '@sentry/browser'

/**
 * Finalizes the subscription process.
 *
 * @returns {Promise<void>} A promise that resolves when the subscription is finalized or rejects with an error.
 */
export async function finalize () {
  try {
    const SubscriptionJS = await getSubscriptionJS()

    return new Promise((resolve, reject) => {
      SubscriptionJS.finalize(
        resolve,
        (error) => {
          Sentry.captureException(error)
          reject(error)
        }
      )
    })
  } catch (error) {
    return Promise.reject(new Error('Failed to initialize SubscriptionJS: ' + error.message))
  }
}
