import * as Sentry from '@sentry/browser'
import { getSubscriptionJS } from './getSubscriptionJS'

/**
 * Handles customer change using the SubscriptionJS library.
 *
 * @param {Object} params - The parameters for the customer change.
 * @param {string} params.token - The authentication token.
 * @param {Object} params.customer - The customer details.
 * @returns {Promise<Object>} A promise that resolves when the customer data is changed or rejects with an error.
 */
export async function customerChange ({ token, customer }) {
  if (!token) throw new Error('Token is required')
  if (!customer) throw new Error('Customer data is required')

  try {
    const SubscriptionJS = await getSubscriptionJS()
    const portalService = new SubscriptionJS.Portal(token)

    return new Promise((resolve, reject) => {
      portalService.customerChange(
        customer,
        resolve,
        (error) => {
          Sentry.captureException(error)
          reject(handleOrderError(error))
        }
      )
    })
  } catch (error) {
    return Promise.reject(new Error('Failed to initialize SubscriptionJS: ' + error.message))
  }
}

/**
 * Error handler for customer data change.
 * @param {Object} error - The error object.
 * @returns {Object} - The processed error object.
 */
function handleOrderError (error) {
  let errorCode = error.errorCode[0]
  if (
    error.errorCode.includes('InvalidCouponCode') ||
    error.errorCode.includes('InactiveCouponCode') ||
    error.errorCode.includes('CouponAlreadyUsed') ||
    error.errorCode.includes('IncompatibleCouponCode')
  ) {
    errorCode = 'INVALID_COUPON_CODE'
  } else if (error.errorMessage.includes('VAT ID')) {
    errorCode = 'INVALID_VAT_ID'
  }

  return { errorCode, errorMessage: error.errorMessage }
}
