import * as Sentry from '@sentry/browser'
import { getPaymentService } from './getPaymentService'
import { getSubscriptionJS } from './getSubscriptionJS'

/**
 * Initiates the upgrade payment process.
 * @param {Object} params - The parameters for the payment process.
 * @param {string} params.token - The token for the portal service.
 * @param {Object} params.order - The order data for the payment.
 * @param {string} [params.paymentMethod='None:None'] - The payment method of the customer.
 * @param {string} params.providerReturnUrl - The URL to which the provider should return.
 * @returns {Promise} - A promise that resolves with the payment service data or rejects with an error.
 */
export async function upgradePayInteractive ({ token, order, paymentMethod = 'None:None', providerReturnUrl }) {
  if (!token) throw new Error('Token is required')
  if (!order) throw new Error('Order is required')
  if (!providerReturnUrl) throw new Error('Provider return URL is required')

  try {
    const SubscriptionJS = await getSubscriptionJS()
    const paymentService = await getPaymentService(providerReturnUrl)
    const portalService = new SubscriptionJS.Portal(token)
    const paymentData = { bearer: paymentMethod }

    return new Promise((resolve, reject) => {
      portalService.upgradePayInteractive(
        paymentService,
        paymentData,
        order,
        resolve,
        (error) => {
          Sentry.captureException(error)
          reject(error)
        }
      )
    })
  } catch (error) {
    throw new Error('Payment service initialization error: ' + error.message)
  }
}
