import * as Sentry from '@sentry/browser'
import { getSubscriptionJS } from './getSubscriptionJS'

/**
 * Creates an order and handles the result.
 * @param {Object} signupService - The signup service instance.
 * @param {Object} cart - The cart data.
 * @param {Object} customer - The customer data.
 * @returns {Promise} - A promise that resolves with the order or rejects with an error.
 */
export async function createOrder ({ cart, customer }) {
  try {
    const SubscriptionJS = await getSubscriptionJS()
    const signupService = new SubscriptionJS.Signup()
    return new Promise((resolve, reject) => {
      signupService.createOrder(
        cart,
        customer,
        resolve,
        (error) => {
          Sentry.captureException(error)
          reject(error)
        }
      )
    })
  } catch (error) {
    return Promise.reject(new Error('Failed to initialize SubscriptionJS: ' + error.message))
  }
}
