import * as Sentry from '@sentry/browser'
import { getSubscriptionJS } from './getSubscriptionJS'

/**
 * Fetches contract details using the portal service.
 * @param {string} token - The authentication token.
 * @returns {Promise<Object>} - A promise that resolves with the contract details or rejects with an error.
 */
export async function getContractDetails (token) {
  if (!token) throw new Error('Token is required')

  try {
    const SubscriptionJS = await getSubscriptionJS()
    const portalService = new SubscriptionJS.Portal(token)

    return new Promise((resolve, reject) => {
      portalService.contractDetails(
        resolve,
        (error) => {
          Sentry.captureException(error)
          reject(error)
        }
      )
    })
  } catch (error) {
    return Promise.reject(new Error('Failed to initialize SubscriptionJS: ' + error.message))
  }
}
