<template>
  <v-row
    justify="center"
    class="justify-center h-full d-flex align-center"
  >
    <div class="px-16 py-8">
      <div
        v-if="error"
        class="justify-center gap-2 d-flex flex-column"
      >
        <v-icon
          color="error"
          size="80"
        >
          mdi-alert-circle
        </v-icon>
        <h2>
          {{ error }}
        </h2>
      </div>
      <div
        v-else-if="productTypeChanged"
        class="text-center"
      >
        <h2>{{ $t('payment.upgrade.success.header') }}</h2>
        <p>{{ $t('payment.upgrade.success.text') }}</p>
      </div>
      <div
        v-else-if="finalized && $route.params.action === 'upgrade'"
        class="text-center"
      >
        <h2>{{ $t('payment.upgrade.pending.header') }}</h2>
        <p>{{ $t('payment.upgrade.pending.text') }}</p>
        <ProgressCircular />
      </div>
      <div v-else>
        <ProgressCircular />
        <h2>
          {{ $t('payment.finalizing') }}
        </h2>
      </div>
    </div>
  </v-row>
</template>

<script>
import bus, { eventNames } from '@/lib/eventBus'
import ProgressCircular from '@/components/ProgressCircular.vue'
import { billwerkApi } from '../billwerk/lib'
import { getProductPackageQuery } from '../productPackages/lib/productQuery'
import { ProductType } from '@/lib/productTypes'
import { Routes } from '@/components/product-finder/routes'
import { showSnackbarMessage } from '@/lib/snackbarMessages'

import CREATE_COMPANY_BILLING from '@/modules/auth/Register/queries/createCompanyBilling.gql'
import COMPANY_BASE from '@/queries/CompanyBase.gql'
import IDENTITY from '@/queries/Identity.gql'

export default {
  components: {
    ProgressCircular
  },
  data () {
    return {
      error: null,
      finalized: false,
      productTypeChanged: false
    }
  },
  watch: {
    identity (newIdentity, old) {
      if (old && newIdentity.company.productType !== old.company.productType) {
        this.productTypeChanged = true
        bus.$emit(eventNames.TRIGGER_ANIMATION)
      }
    }
  },
  mounted () {
    if (this.$route.query.trigger === 'Abort') {
      return this.$router.push({ name: 'payment' })
    }
    this.finalize()
  },
  methods: {
    async finalize () {
      try {
        if (this.$route.query.finalized !== 'true') {
          await billwerkApi.finalize()
        }
        if (this.$route.params.action === 'signup') {
          await this.createCompanyBilling()
        }
        this.finalized = true
      } catch (error) {
        this.error = this.$t('alerts.settings.payment-method.error')
      }
    },

    async createCompanyBilling () {
      try {
        const { companyName = '', productType, readableIds, isYearly } = getProductPackageQuery() || {}
        await this.$apollo.mutate({
          mutation: CREATE_COMPANY_BILLING,
          variables: {
            input: {
              companyName,
              productType,
              components: readableIds ?? [],
              isYearly: productType === ProductType.BEGINNER ? false : isYearly
            }
          }
        })

        this.$tracking.event('Account Creation', 'Loaded', 'Initial App Load')
        sessionStorage.removeItem('productPackageQuery')
        return this.$router.push({ name: 'signup-markero', params: { step: Routes.WEBSITE } })
      } catch (error) {
        showSnackbarMessage('error', this.$t('alerts.payment.company-billing.error'))
        this.$router.push({ name: 'product-packages' })
      }
    }
  },

  apollo: {
    identity: {
      query: IDENTITY,
      pollInterval: 5000
    },
    company: {
      query: COMPANY_BASE,
      pollInterval: 5000,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      }
    }
  }
}
</script>
